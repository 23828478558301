import React, { useEffect, useState } from 'react'
// 
import '../STYLESHEETS/Contact.css'
import Banner from '../UTILITIES/Banner'
import Footer from '../UTILITIES/Footer'

import imgContact from '../../PHOTOS/umani-tiny-homes-interior-loft-2.jpg'
import imgContact2 from '../../PHOTOS/umani-tiny-homes-interior4.jpg'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { MdAlternateEmail } from 'react-icons/md'
import Navigation from '../UTILITIES/Navigation'
import { submitContactForm } from '../../firebase'

export default function Contact() {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");

    const submitForm = () => {
        const fullName = document.querySelector("#tbFullName").value
        const email = document.querySelector("#tbEmail").value
        const businessName = document.querySelector("#tbBusinessName").value
        const message = document.querySelector("#tbMessage").value

        if (fullName == "" || email == "" || message == "") {
            setAlertMessage("Include required fields.");
            setShowAlert(true);
        } else {
            submitContactForm(fullName, email, businessName, message)
                .then(() => {
                    setAlertMessage("Success. Thank you!");
                    setShowAlert(true);
                })
                .catch(() => {
                    setAlertMessage("Sorry. Try Again.");
                    setShowAlert(true);
                })
        }

        document.querySelector("#tbFullName").value = ""
        document.querySelector("#tbEmail").value = ""
        document.querySelector("#tbBusinessName").value = ""
        document.querySelector("#tbMessage").value = ""
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Navigation />
            <div className='wrapper'>
                <Banner message="Leave us a message. Go on." />
                <div className='p-md'>
                    <h1 className='contact-title font1'>Leave us a message,<br />
                        or don't. Up to you.
                    </h1>
                    <br />
                </div>

                <div className='form'>
                    <input id="tbFullName" placeholder='Full Name' type="text" className='form-text' />
                    <input id="tbEmail" placeholder='Email' type="email" className='form-text' />
                    <input id="tbBusinessName" placeholder='Business Name' type="text" className='form-text' />
                    <textarea id="tbMessage" placeholder='Leave a message...' className='form-text font1'></textarea>
                    <button className='btn-black' onClick={submitForm}>Send</button>
                    {showAlert ?
                        <p className='font1 center p-sm'>{alertMessage}</p> : <p></p>
                    }
                </div>
                <div className='bg-black p-lg white font1'>
                    <h1 className='contact-title'>Contact Info</h1>
                    <div className='contact-block'>
                        <p className='contact-info'><BsFillTelephoneFill className='contact-icon' /> (619) 600 - 1580</p>
                        {/* <p className='contact-info'><FaMapMarkerAlt className='contact-icon' /> 1788 Mozart St, San Diego CA, 91913</p> */}
                        <p className='contact-info'><MdAlternateEmail className='contact-icon' />living@umanitiny.com</p>
                    </div>
                </div>
                <div>
                    <img src={imgContact} className="form-img" />
                </div>
                <Footer />
            </div>
        </div>
    )
}
