
import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './COMPONENTS/STANDARD/Home';
import TinyHomes from './COMPONENTS/STANDARD/TinyHomes';
import Contact from './COMPONENTS/STANDARD/Contact';
import Gallery from './COMPONENTS/STANDARD/Gallery';

function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/tiny-homes" element={<TinyHomes />} />
      <Route exact path="/gallery" element={<Gallery />} />
      <Route exact path="/contact-us" element={<Contact />} />
    
    </Routes>
  );
}

export default App;
