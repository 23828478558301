import React, { Component } from 'react'
import '../STYLESHEETS/Banner.css'
// 
import { BsStars } from 'react-icons/bs'

export default class Banner extends Component {
  render() {
    return (
        <div className='banner'>
          <p className='banner-text'>{this.props.message}{" "}<BsStars /></p>
        </div>
      )
  }
}

