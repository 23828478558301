import React, { useEffect } from 'react'
// 
import '../STYLESHEETS/Home.css'
// 
import imgHomeLandscape from '../../PHOTOS/umani-tiny-homes-exterior-landscape.jpg'
import imgHomeLandscape2 from '../../PHOTOS/umani-tiny-homes-exterior-portrait2.jpg'
import imgHomePortrait from '../../PHOTOS/umani-tiny-homes-exterior-portrait.jpg'
import imgModel from '../../PHOTOS/umani-tiny-homes-model.PNG'
import imgTest from '../../PHOTOS/test-tinyhome.jpg'
// 
import { RiHomeSmileFill } from 'react-icons/ri'
import { BiHappyBeaming } from 'react-icons/bi'
import Banner from '../UTILITIES/Banner'
import Footer from '../UTILITIES/Footer'
import Navigation from '../UTILITIES/Navigation'

export default function Home() {
    useEffect(() => {
        document.title = "Umani Tiny Homes"
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Navigation />
            
            <div className='wrapper'>
            <Banner message={"Home, Sweet, Tiny Home"} />
            <h1 className='home-main-title'>
                UMANI TINY
            </h1>
            <div className='split'>
                <div className='home-panel1 relative'>
                    <img src={imgHomePortrait} className="home-main-img" />
                </div>

                <div className='home-panel2'>
                    <div className='home-panel2-wrapper bg-gray'>
                        <p className='home-panel2-text white'>
                            Tiny homes are perfect for a comfy living space. Buy one to rent out, or simply live in. There is no wrong answer when you get yourself an Umani Tiny Home.
                        </p>
                        <RiHomeSmileFill className='home-icon rotate-left bottomRight white' />
                    </div>
                    <div className='home-panel2-wrapper m-md'>
                        <h1 className='home-panel2-qui'>How much?</h1>
                        <h1 className='home-panel2-ans rotate-left bg-blue'>$85,000</h1>

                        <h1 className='home-panel2-qui'>By when?</h1>
                        <h1 className='home-panel2-ans rotate-right bg-lightBlue'>4 - 6 weeks</h1>
                        <BiHappyBeaming className='home-icon bottomLeft rotate-right yellow' />
                    </div>
                    <br />
                    <h2 className='home-panel2-subtitle'>No fine print. Only benefits.</h2>
                <div className='home-panel2-wrapper bg-gray'>
                    <p className='home-panel2-text white'>
                        Complete with a living area, bed loft, kitchen, bathroom, with plenty of storage space!<br />
                        Universal floor plan, quick delivery, and high quality certified construction.
                    </p>
                    <RiHomeSmileFill className='home-icon rotate-left bottomRight white' />
                </div>
                <br />
                <div className='home-panel2-wrapper'>
                    <img className='home-panel2-img' src={imgHomeLandscape2} />
                    <RiHomeSmileFill className='home-icon rotate-right bottomLeft white' />
                </div>
                </div>
            </div>

            <Footer />
            </div>

        </div>
    )
}
