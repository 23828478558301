import React, {useEffect} from 'react'
// 
import '../STYLESHEETS/Navigation.css'
// 
import { TbMenu2 } from 'react-icons/tb'
import { IoCloseOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom';

export default function Navigation() {
    function openNav() {
        document.querySelector(".navbody").style.width = "100vw";
    }

    function closeNav() {
        document.querySelector(".navbody").style.width = "0";
    }

    useEffect(() => {
      if (window.innerWidth < 800) {
        closeNav()
      }
    }, [])
    
    return (
        <div>
            <div className='navbar font1'>
                <div className='navbar-top'>
                    <h1 className='navbar-title font-1'>Umani Tiny</h1>
                    <TbMenu2 className='navbar-icon' onClick={openNav} />
                </div>
                <div className='navbody'>
                    <div className='navbody-top'>
                        <h1 className='navbody-title font-1'>Umani Tiny Homes</h1>
                        <IoCloseOutline className='navbody-icon white' onClick={closeNav} />
                    </div>
                    <div className='navbody-links'>
                        <Link className='Link' onClick={closeNav} to="/">Home</Link>
                        {/* <Link className='Link' onClick={closeNav} to="/tiny-homes">Tiny Homes</Link> */}
                        <Link className='Link' onClick={closeNav} to="/gallery">Gallery</Link>
                        <Link className='Link' onClick={closeNav} to="/contact-us">Contact</Link>
                    </div>
                    <p className='navbody-footer'>
                        &copy; Umani Tiny Homes 2022. All Rights Reserved
                    </p>
                </div>
            </div>

        </div >
    )
}
