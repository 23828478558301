import React, { useEffect } from 'react'
import Banner from '../UTILITIES/Banner'
import Footer from '../UTILITIES/Footer'
import Navigation from '../UTILITIES/Navigation'
// 
import '../STYLESHEETS/TinyHomes.css'

import imgStepOne from '../../PHOTOS/umani-tiny-homes-exterior-portrait2.jpg'

export default function TinyHomes() {
  useEffect(() => {
    window.scrollTo(0,0)
    document.title = "How we build our tiny homes"
  }, [])

  return (
    <div className='font1'>
      <Navigation />
      <Banner message="Work smart, not hard" />
      {/* <h1 className='tinyhomes-maintitle'>How we build a tiny home.</h1>
      <div className='p-md'>
        <h1 className='tinyhomes-title'>1. Step one</h1>
        <p className='tinyhomes-text'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam et orci in justo scelerisque dignissim sit amet eget risus. Fusce sit amet ante auctor, auctor ante sit amet, laoreet nunc. Aliquam erat volutpat. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Praesent eu eleifend velit. <br /><br />
          Integer ante enim, tempor eu nisi non, condimentum rutrum turpis. Maecenas placerat tempor nisi, eu semper massa maximus vel. Curabitur eu pretium orci, eget rutrum ipsum. Donec congue dignissim condimentum. Fusce sodales erat sit amet libero placerat laoreet. Praesent non rhoncus augue. Maecenas ornare lectus tristique imperdiet finibus. Donec faucibus mauris in erat ultricies, nec fermentum ante hendrerit.
        </p>
        <img src={imgStepOne} className="tinyhomes-img" />
      </div>
      <div className='p-md'>
        <h1 className='tinyhomes-title'>2. Step two</h1>
        <p className='tinyhomes-text'>
          Vivamus elementum, tellus in ullamcorper ornare, dui arcu finibus sapien, id vehicula turpis massa et elit. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer non interdum est. Quisque tincidunt eu ante non vulputate. Integer in sapien vitae nunc pulvinar convallis non a mi. Phasellus felis enim, consectetur eu metus eu, laoreet vulputate mauris. Quisque tempor lectus enim, eu consequat turpis blandit id.<br /><br /> Aenean mauris purus, convallis eu nunc ut, congue placerat ante. Morbi vel sem id tortor ullamcorper consequat. Maecenas lobortis metus eu nibh pretium sodales. Sed sit amet tempor odio. Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus elit felis, vehicula et sem vitae, hendrerit bibendum tellus. Pellentesque felis enim, tincidunt condimentum erat sit amet, tempor interdum leo. In odio risus, dapibus non lorem ac, cursus sollicitudin turpis.
        </p>
        <img src={imgStepOne} className="tinyhomes-img" />
      </div>
      <div className='p-md'>
        <h1 className='tinyhomes-title'>3. Step three</h1>
        <p className='tinyhomes-text'>
          Quisque et felis eget ante dignissim convallis. Integer molestie justo in purus euismod, eget fermentum ligula scelerisque. Maecenas vel tincidunt neque, non pulvinar leo. Morbi a urna dolor. Nunc nec tellus gravida, convallis purus in, maximus lorem. Ut semper pulvinar mi, at dignissim erat gravida sed.<br /><br /> Donec cursus vehicula mauris, id commodo libero. Cras venenatis dictum nisi, eget suscipit dolor fringilla non. In faucibus cursus ex quis porta. Phasellus elementum venenatis blandit. Sed maximus eu dui sit amet malesuada. Mauris in ex sed est finibus lacinia at at augue. Proin sodales fermentum nulla non feugiat.
        </p>
        <img src={imgStepOne} className="tinyhomes-img" />
      </div>
      <div className='p-md'>
        <h1 className='tinyhomes-title'>4. Step four</h1>
        <p className='tinyhomes-text'>
          Mauris metus lectus, semper a massa nec, eleifend vehicula turpis. Aliquam rhoncus, leo quis vulputate aliquet, lacus ipsum tincidunt nisl, ut dapibus ligula enim id diam. Nullam commodo, ligula in pretium vehicula, orci lacus vehicula nunc, eu consectetur nulla turpis at orci.<br /><br /> Morbi interdum et massa non fringilla. Nunc faucibus tincidunt dolor, ut egestas dui volutpat sit amet. Aliquam feugiat urna neque, nec finibus metus cursus a. Sed ullamcorper dolor non ipsum egestas sagittis. Morbi nulla nulla, porttitor at urna vitae, sollicitudin luctus mi.
        </p>
        <img src={imgStepOne} className="tinyhomes-img" />
      </div>
      <hr /> */}
      <h1 className='tinyhomes-maintitle'>How we ship a tiny home.</h1>
      <div className='p-md'>
        <h1 className='tinyhomes-title'>Delivered in 4 - 6 weeks.</h1>
        <p className='tinyhomes-text'>
          Description of what the shipping process is.
        </p>
        <img src={imgStepOne} className="tinyhomes-img" />
      </div>

      <Footer />
    </div>
  )
}
