import React from 'react'
// 
import '../STYLESHEETS/Gallery.css'
import Footer from '../UTILITIES/Footer'
import Navigation from '../UTILITIES/Navigation'
// 
import img1 from '../../PHOTOS/umani-tiny-homes-exterior-portrait.jpg'
import img2 from '../../PHOTOS/umani-tiny-homes-exterior-portrait2.jpg'
import img3 from '../../PHOTOS/umani-tiny-homes-interior1.jpg'
import img4 from '../../PHOTOS/umani-tiny-homes-interior-loft-2.jpg'
import img5 from '../../PHOTOS/umani-tiny-homes-interior2.jpg'
import img6 from '../../PHOTOS/umani-tiny-homes-interior3.jpg'
import img7 from '../../PHOTOS/umani-tiny-homes-interior4.jpg'
// import img7 from '../../PHOTOS/umani-tiny-homes-interior5.jpg'
import img8 from '../../PHOTOS/umani-tiny-homes-interior6.jpg'

export default function Gallery() {
  const images = [
    img1, img2, img3, img4, img5, img6, img7, img8
  ]
  return (
    <div>
      <Navigation />
      <div className='gallery'>
        {images.map((img, i) => {
          return (
            <img key={i} className='gallery-img' src={img} />
          )
        })}
      </div>
      <Footer />
    </div>
  )
}
