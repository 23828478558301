import React from 'react'
// 
import '../STYLESHEETS/Footer.css'
// 
import { AiFillInstagram } from 'react-icons/ai'
import { BsFacebook, BsTwitter } from 'react-icons/bs'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className='footer'>
            <h1 className='footer-title'>Umani Tiny Homes</h1>
            <p className='footer-text'>
                Umani Tiny Homes builds the perfect living space in a small package. Our certified constructors work hard to provide simmplicty without leaving out quality.<br/><br/>
                Trust us to provide a quick an easy purchasing experience. We focus on one product only; getting rid of complexity in the entire building and purchasing process. 
            </p>
            <div className='socials'>
                <a className='white' href="https://www.instagram.com/umanitiny/" target="_blank"><AiFillInstagram /></a>
            </div>
            <div className='footer-links'>
                <Link className='footer-Link' to="/">Home</Link>
                {/* <Link className='footer-Link' to="/tiny-homes">Tiny Homes</Link> */}
                <Link className='footer-Link' to="/gallery">Gallery</Link>
                <Link className='footer-Link' to="/contact-us">Contact Us</Link>
            </div>
            <p className='copyright'>&copy; Copyright 2022 Umani Tiny Homes. All Rights Reserved.</p>
        </div>
    )
}
