// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { doc, setDoc } from "firebase/firestore";
import {randomString} from './Global'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCcBBM4pd45ok8v3R9u2VUfuN8v59VCMVI",
  authDomain: "umani-tiny.firebaseapp.com",
  projectId: "umani-tiny",
  storageBucket: "umani-tiny.appspot.com",
  messagingSenderId: "317453394114",
  appId: "1:317453394114:web:5c094efa63d591f5ca1b5b",
  measurementId: "G-SN4PEQMJ2J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export const submitContactForm = async (fullName, email, businessName, message) => {
  await setDoc(doc(db, "ContactEntries", randomString(30)), {
    FullName: fullName,
    Email: email,
    BusinessName: businessName,
    Message: message
  });

}
